/* eslint-disable camelcase */
// create slice for individual pursuit(it will initialized when choose another pursuit or log out)
import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { ApiClientRfx } from "store/api/apiTypes";
import { store } from "../index";

export interface IKeyDateRow {
  id: number;
  start_date?: string;
  date?: string;
  activity?: string;
  name?: string;
  client_rfx: number;
  notes?: string;
}

interface IInitialState {
  pursuitData?: ApiClientRfx;
  keyDates: IKeyDateRow[];
}

const initialState: IInitialState = {
  pursuitData: undefined,
  keyDates: [],
};

export const pursuitSlice = createSlice({
  name: "pursuit",
  initialState,
  reducers: {
    updateData: (state, { payload }: PayloadAction<IKeyDateRow>) => {
      state.keyDates = state.keyDates.map((data: IKeyDateRow) =>
        data.id === payload.id ? payload : data
      );
    },
    addData: (state, { payload }: PayloadAction<IKeyDateRow>) => {
      state.keyDates.push(payload);
    },
    setData: (state, { payload }: PayloadAction<IKeyDateRow[]>) => {
      state.keyDates = payload;
    },
  },
});

// RootState should be defined in store/index.ts
export type TRootState = ReturnType<typeof store.getState>;

const { addData, updateData, setData } = pursuitSlice.actions;
export const setDataAsync = (list: IKeyDateRow[]) => (dispatch: Dispatch) =>
  dispatch(setData(list));
export const updateDataAsync = (item: IKeyDateRow) => (dispatch: Dispatch) =>
  dispatch(updateData(item));
export const addDataAsync = (item: IKeyDateRow) => (dispatch: Dispatch) =>
  dispatch(addData(item));
export const selectPursuitData = (state: TRootState) => state.pursuitSlice;
export const selectNextMileStone = (state: Partial<TRootState>): string => {
  if (!state.pursuitSlice.keyDates.length) return "";
  const validEndDates = state.pursuitSlice.keyDates
    .filter(
      ({ date }) => !!date && new Date(date).getTime() >= new Date().getTime()
    )
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  if (!validEndDates.length) return "";
  return validEndDates[0].name;
};

export default pursuitSlice.reducer;
