/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";
import { transformPaginatedResponse, getErrorMessage } from "utils/api-utils";
import {
  transformPaginatedResponseWithCount,
  API_RESPONSE_LIMIT,
} from "utils/api-util";
import { baseQuery } from "./baseQuery";
import { settlePromises } from "./queryAggregator";

import { ApiResponseHandler } from "./apiResponseHandler";
import { HttpStatusCode } from "./httpStatusCodes";

export const api = createApi({
  baseQuery,
  reducerPath: "suppliersApi",
  tagTypes: [
    "SupplierInfo",
    "SupplierTeam",
    "QuoteTemplate",
    "SuppliersOfPursuit",
    "SuppliersOfOrg",
    "SupplierQuotes",
    "SupplierQuotesInputs",
  ],
  endpoints: (build) => ({
    getSupplierQuoteInputs: build.query({
      query: (rfxId, limit = 1000) =>
        `client_rfxs/${rfxId}/supplier_quote_input_fields/?limit=${limit}`,
      keepUnusedDataFor: 0,
      providesTags: ["SupplierQuotesInputs"],
      transformResponse: transformPaginatedResponse,
    }),

    addSupplierQuoteInput: build.mutation({
      query: ({ rfxId, ...body }) => ({
        url: `client_rfxs/${rfxId}/supplier_quote_input_fields/`,
        method: "POST",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_201_CREATED,
            response.status,
            "Failed to add a new quote input",
            () => getErrorMessage("adding new quote input", meta)
          ),
      }),
      invalidatesTags: ["SupplierQuotesInputs"],
    }),
    bulkAddSupplierQuoteInput: build.mutation({
      query: ({ rfxId, ...body }) => ({
        url: `client_rfxs/${rfxId}/supplier_quote_input_fields/bulk_create`,
        method: "POST",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_201_CREATED,
            response.status,
            "Failed to add quotes inputs",
            () => getErrorMessage("adding new quote inputs", meta)
          ),
      }),
      invalidatesTags: ["SupplierQuotesInputs"],
    }),

    deleteSupplierQuoteInput: build.mutation({
      query: ({ rfxId, id }) => ({
        url: `client_rfxs/${rfxId}/supplier_quote_input_fields/${id}/`,
        method: "DELETE",
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_204_NO_CONTENT,
            response.status,
            "Failed to delete the quote input",
            () => getErrorMessage("deleting supplier quote input", meta)
          ),
      }),
      invalidatesTags: ["SupplierQuotesInputs"],
    }),

    // list/add/update/delete suppliers of org
    getSuppliersOfOrg: build.query({
      query: (orgId) => `organizations/${orgId}/suppliers/?limit=1000`,
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponse,
    }),

    addSupplierOfOrg: build.mutation({
      query: ({ orgId, ...body }) => ({
        url: `organizations/${orgId}/suppliers/`,
        method: "POST",
        body,
      }),
      transformResponse: transformPaginatedResponse,
    }),

    // list/add/update/delete suppliers of pursuit
    getSuppliersOfPursuit: build.query({
      query: (rfxId) => `client_rfxs/${rfxId}/suppliers/`,
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponse,
      providesTags: ["SuppliersOfPursuit"],
    }),
    addSupplierOfPursuit: build.mutation({
      query: ({ rfxId, ...body }) => ({
        url: `client_rfxs/${rfxId}/suppliers/`,
        method: "POST",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_201_CREATED,
            response.status,
            "Failed to add a new supplier",
            () => getErrorMessage("adding new supplier", meta)
          ),
      }),
      transformResponse: transformPaginatedResponse,
      invalidatesTags: ["SuppliersOfPursuit"],
    }),
    updateSupplierOfPursuit: build.mutation({
      query: ({ rfxId, supplierId, ...body }) => ({
        url: `client_rfxs/${rfxId}/suppliers/${supplierId}/`,
        method: "PATCH",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_200_OK,
            response.status,
            "Failed to update the supplier",
            () => getErrorMessage("updating the supplier", meta)
          ),
      }),
      transformResponse: transformPaginatedResponse,
      invalidatesTags: ["SuppliersOfPursuit"],
    }),
    deleteSupplierOfPursuit: build.mutation({
      query: ({ rfxId, supplierId }) => ({
        url: `client_rfxs/${rfxId}/suppliers/${supplierId}/`,
        method: "DELETE",
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_204_NO_CONTENT,
            response.status,
            "Failed to delete the supplier",
            () => getErrorMessage("deleting the supplier", meta)
          ),
      }),
      invalidatesTags: ["SuppliersOfPursuit"],
    }),

    notifySupplier: build.mutation({
      query: ({ rfxId, supplierId, ...body }) => ({
        url: `client_rfxs/${rfxId}/suppliers/${supplierId}/quote_request/`,
        method: "POST",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_201_CREATED,
            response.status,
            "Failed to notify the supplier",
            () => getErrorMessage("notifying this supplier", meta)
          ),
      }),
      invalidatesTags: ["SuppliersOfPursuit"],
    }),

    getFirstSupplierQuotes: build.query({
      query: ({ rfxId, supplierId, limit, offset }) =>
        `client_rfxs/${rfxId}/suppliers/${supplierId}/quotes/?limit=${limit}&offset=${offset}`,
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponseWithCount,
      providesTags: ["SupplierQuotes"],
    }),
    getRestSupplierQuotes: build.query({
      async queryFn(arg, _, __, apiBaseQuery) {
        const {
          rfxId,
          supplierId,
          count,
          baseLimit = API_RESPONSE_LIMIT,
        } = arg;
        const pages = Math.ceil(count / baseLimit);
        let limit;
        let offset;
        const promises = [];
        // page : 0 is already fetched by getFirstSupplierQuotes API, so start from page : 1
        for (let page = 1; page < pages; page += 1) {
          limit = page === pages - 1 ? count - page * baseLimit : baseLimit;
          offset = page * baseLimit;
          const promise = Promise.resolve(
            apiBaseQuery({
              url: `client_rfxs/${rfxId}/suppliers/${supplierId}/quotes/?limit=${limit}&offset=${offset}`,
            })
          );
          promises.push(promise);
        }
        const responses = await settlePromises(promises);
        return responses;
      },
      keepUnusedDataFor: 0,
    }),
    addSupplierQuotes: build.mutation({
      async queryFn(arg, _, __, apiBaseQuery) {
        const suppliers = arg;
        const promises = [];
        suppliers.forEach(({ rfxId, supplierId, ...body }) => {
          const promise = Promise.resolve(
            apiBaseQuery({
              url: `client_rfxs/${rfxId}/suppliers/${supplierId}/quotes/`,
              method: "POST",
              body,
            })
          );
          promises.push(promise);
        });

        const responses = await settlePromises(promises);
        return responses;
      },
    }),
    updateSupplierQuotes: build.mutation({
      async queryFn(arg, _, __, apiBaseQuery) {
        const suppliers = arg;
        const promises = [];
        suppliers.forEach(({ rfxId, supplierId, quoteId, ...body }) => {
          const promise = Promise.resolve(
            apiBaseQuery({
              url: `client_rfxs/${rfxId}/suppliers/${supplierId}/quotes/${quoteId}/`,
              method: "PATCH",
              body,
            })
          );
          promises.push(promise);
        });

        const responses = await settlePromises(promises);
        return responses;
      },
    }),

    getFirstQuotes: build.query({
      query: ({ rfxId, limit, offset }) =>
        `client_rfxs/${rfxId}/quotes/?limit=${limit}&offset=${offset}`,
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponseWithCount,
    }),
    getRestQuotes: build.query({
      async queryFn(arg, _, __, apiBaseQuery) {
        const { rfxId, count, baseLimit = API_RESPONSE_LIMIT } = arg;
        const pages = Math.ceil(count / baseLimit);
        let limit;
        let offset;
        const promises = [];
        // page : 0 is already fetched by getFirstQuotes API, so start from page : 1
        for (let page = 1; page < pages; page += 1) {
          limit = page === pages - 1 ? count - page * baseLimit : baseLimit;
          offset = page * baseLimit;
          const promise = Promise.resolve(
            apiBaseQuery({
              url: `client_rfxs/${rfxId}/quotes/?limit=${limit}&offset=${offset}`,
            })
          );
          promises.push(promise);
        }
        const responses = await settlePromises(promises);
        return responses;
      },
    }),

    getBuildingServices: build.query({
      query: ({ rfxId, limit, offset }) =>
        `client_rfxs/${rfxId}/buildings_services/?limit=${limit}&offset=${offset}`,
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponseWithCount,
    }),

    getFirstBuildingServices: build.query({
      query: ({ rfxId, limit = API_RESPONSE_LIMIT, offset = 0 }) =>
        `client_rfxs/${rfxId}/buildings_services/?limit=${limit}&offset=${offset}`,
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponseWithCount,
    }),

    getRestBuildingServices: build.query({
      async queryFn(arg, _, __, apiBaseQuery) {
        const { rfxId, count, baseLimit = API_RESPONSE_LIMIT } = arg;
        const pages = Math.ceil(count / baseLimit);
        let limit;
        let offset;
        const promises = [];
        // page : 0 is already fetched by getFirstBuildingServices API, so start from page : 1
        for (let page = 1; page < pages; page += 1) {
          limit = page === pages - 1 ? count - page * baseLimit : baseLimit;
          offset = page * baseLimit;
          const promise = Promise.resolve(
            apiBaseQuery({
              url: `client_rfxs/${rfxId}/buildings_services/?limit=${limit}&offset=${offset}`,
            })
          );
          promises.push(promise);
        }
        const responses = await settlePromises(promises);
        return responses;
      },
    }),

    updateSupplierRequested: build.mutation({
      query: ({ rfxId, serviceByBuildingId, supplierId, isRequested }) => ({
        url: `client_rfxs/${rfxId}/buildings_services/${serviceByBuildingId}/suppliers/${supplierId}/`,
        method: "PATCH",
        body: {
          is_requested: isRequested,
        },
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_200_OK,
            response.status,
            "Failed to request supplier for a quote",
            () => getErrorMessage("requesting the supplier", meta)
          ),
      }),
    }),

    updateSelectedSuppliers: build.mutation({
      async queryFn(arg, _, __, apiBaseQuery) {
        const suppliers = arg;
        const promises = [];
        suppliers.forEach(({ serviceByBuildingId, selectedSupplierId }) => {
          const promise = Promise.resolve(
            apiBaseQuery({
              url: `service-by-building/${serviceByBuildingId}/`,
              method: "PATCH",
              body: {
                selected_supplier: selectedSupplierId,
              },
            })
          );
          promises.push(promise);
        });

        const responses = settlePromises(promises);
        if (responses.errors?.length > 0) {
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_200_OK,
            responses.errors[0].status,
            "Failed to update selected suppliers",
            () =>
              getErrorMessage(
                "updating the selected supplier",
                responses.errors[0].data
              )
          );
        }
        return responses;
      },
    }),

    getSupplierAssumption: build.query({
      query: ({ rfxId, supplierId }) => {
        const supplier = supplierId ? `supplier=${supplierId}` : "";
        return `client_rfxs/${rfxId}/supplier_assumptions/?${supplier}`;
      },
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponse,
      providesTags: ["SupplierInfo"],
    }),
    createSupplierAssumptions: build.mutation({
      async queryFn(assumptions, _, __, apiBaseQuery) {
        const promises = assumptions.map((assumption) =>
          Promise.resolve(
            apiBaseQuery({
              url: `client_rfxs/${assumption.client_rfx}/supplier_assumptions/`,
              method: "POST",
              body: { ...assumption },
            })
          )
        );
        const responses = await settlePromises(promises);
        return responses;
      },
    }),
    updateSupplierAssumptions: build.mutation({
      async queryFn(assumptions, _, __, apiBaseQuery) {
        const promises = assumptions.map((assumption) =>
          Promise.resolve(
            apiBaseQuery({
              url: `client_rfxs/${assumption?.client_rfx}/supplier_assumptions/${assumption?.id}/`,
              method: "PUT",
              client_rfx: assumption?.client_rfx,
              body: { ...assumption },
            })
          )
        );
        const responses = await settlePromises(promises);
        return responses;
      },
    }),
    /**
     * Supplier Info Queries
     */
    getSupplierInfo: build.query({
      query: ({ rfxId, supplierId }) =>
        `client_rfxs/${rfxId}/suppliers/${supplierId}/`,
      keepUnusedDataFor: 0,
      providesTags: ["SupplierInfo"],
    }),

    updateSupplierInfo: build.mutation({
      query: ({ rfxId, supplierId, ...body }) => ({
        url: `client_rfxs/${rfxId}/suppliers/${supplierId}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["SupplierInfo"],
    }),

    getAttributes: build.query({
      query: () => "organizations/attributes/",
      transformResponse: transformPaginatedResponse,
    }),
    addSupplierProfile: build.mutation({
      query: ({ supplierId, ...body }) => ({
        url: `suppliers/${supplierId}/profile/`,
        method: "POST",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_201_CREATED,
            response.status,
            "Failed to add new supplier profile!",
            () => getErrorMessage("adding a new supplier profile", meta)
          ),
      }),
    }),
    inviteTeamMembers: build.mutation({
      query: ({ supplierOrgId, emails }) => ({
        url: `organizations/${supplierOrgId}/users/`,
        method: "POST",
        body: {
          emails,
        },
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_201_CREATED,
            response.status,
            "Failed to invite team members!",
            () => getErrorMessage("inviting supplier team members", meta)
          ),
      }),
      invalidatesTags: ["SupplierTeam"],
    }),
    getSupplierProfile: build.query({
      query: (orgId) => `organizations/${orgId}/supplier/profile/`,
      validateStatus: (response, meta) =>
        ApiResponseHandler.handleApiResponseStatus(
          HttpStatusCode.HTTP_200_OK,
          response.status,
          "Failed to get supplier profile",
          () => getErrorMessage("getting supplier profile", meta)
        ),
      keepUnusedDataFor: 0,
    }),

    updateSupplierProfile: build.mutation({
      query: ({ supplierId, ...body }) => ({
        url: `suppliers/${supplierId}/profile/`,
        method: "PATCH",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_200_OK,
            response.status,
            "Failed to update supplier profile",
            () => getErrorMessage("updating supplier profile", meta)
          ),
      }),
    }),

    getSupplierTeamMembers: build.query({
      query: (orgId) => `organizations/${orgId}/users/`,
      validateStatus: (response, meta) =>
        ApiResponseHandler.handleApiResponseStatus(
          HttpStatusCode.HTTP_200_OK,
          response.status,
          "Failed to get the supplier profile!",
          () => getErrorMessage("getting supplier profile", meta)
        ),
      transformResponse: transformPaginatedResponse,
      keepUnusedDataFor: 0,
      providesTags: ["SupplierTeam"],
    }),

    deleteSupplierTeamMembers: build.mutation({
      query: ({ orgId, userId }) => ({
        url: `organizations/${orgId}/users/${userId}/`,
        method: "DELETE",
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_204_NO_CONTENT,
            response.status,
            "Failed to delete supplier team member",
            () => getErrorMessage("deleting supplier team member", meta)
          ),
      }),
    }),

    getQuoteTemplates: build.query({
      query: (orgId, limit = 2000) =>
        `suppliers/${orgId}/quote_input_fields/?limit=${limit}`,
      transformResponse: transformPaginatedResponse,
      providesTags: ["QuoteTemplate"],
    }),

    addQuoteTemplate: build.mutation({
      query: ({ orgId, ...body }) => ({
        url: `suppliers/${orgId}/quote_input_fields/`,
        method: "POST",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_201_CREATED,
            response.status,
            "Failed to create a quote template",
            () => getErrorMessage("creating a quote template", meta)
          ),
      }),
      invalidatesTags: ["QuoteTemplate"],
    }),

    bulkAddQuoteTemplate: build.mutation({
      query: ({ orgId, ...body }) => ({
        url: `suppliers/${orgId}/quote_input_fields/bulk_create/`,
        method: "POST",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_201_CREATED,
            response.status,
            "Failed to create quote templates",
            () => getErrorMessage("creating quote templates", meta)
          ),
      }),
      invalidatesTags: ["QuoteTemplate"],
    }),
    deleteQuoteTemplate: build.mutation({
      query: ({ orgId, quoteId }) => ({
        url: `suppliers/${orgId}/quote_input_fields/${quoteId}/`,
        method: "DELETE",
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_204_NO_CONTENT,
            response.status,
            "Failed to delete quote templates",
            () => getErrorMessage("deleting quote templates", meta)
          ),
      }),
      invalidatesTags: ["QuoteTemplate"],
    }),
    getOrgSuppliers: build.query({
      query: (orgId, limit = 500) =>
        `organizations/${orgId}/suppliers/?limit=${limit}`,
      transformResponse: transformPaginatedResponse,
      providesTags: ["SuppliersOfOrg"],
    }),
    addOrgSupplier: build.mutation({
      query: ({ orgId, ...body }) => ({
        url: `organizations/${orgId}/suppliers/`,
        method: "POST",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_201_CREATED,
            response.status,
            "Failed to add a new supplier",
            () => getErrorMessage("adding a new supplier", meta)
          ),
      }),
      transformResponse: transformPaginatedResponse,
      invalidatesTags: ["SuppliersOfOrg"],
    }),
  }),
});

export const {
  useGetSupplierQuoteInputsQuery,
  useAddSupplierQuoteInputMutation,
  useBulkAddSupplierQuoteInputMutation,
  useDeleteSupplierQuoteInputMutation,

  useGetSuppliersOfOrgQuery,
  useAddSupplierOfOrgMutation,
  useGetSuppliersOfPursuitQuery,
  useAddSupplierOfPursuitMutation,
  useUpdateSupplierOfPursuitMutation,
  useDeleteSupplierOfPursuitMutation,

  useNotifySupplierMutation,

  useGetFirstSupplierQuotesQuery,
  useGetRestSupplierQuotesQuery,
  useAddSupplierQuotesMutation,
  useUpdateSupplierQuotesMutation,

  useGetFirstQuotesQuery,
  useGetRestQuotesQuery,

  useGetBuildingServicesQuery,
  useGetFirstBuildingServicesQuery,
  useGetRestBuildingServicesQuery,
  useUpdateSupplierRequestedMutation,
  useUpdateSelectedSuppliersMutation,

  useGetSupplierAssumptionQuery,
  useCreateSupplierAssumptionsMutation,
  useUpdateSupplierAssumptionsMutation,

  useGetSupplierInfoQuery,
  useUpdateSupplierInfoMutation,

  useGetAttributesQuery,
  useAddSupplierProfileMutation,
  useInviteTeamMembersMutation,

  useGetOrgSuppliersQuery,
  useAddOrgSupplierMutation,
  useGetQuoteTemplatesQuery,
  useAddQuoteTemplateMutation,
  useBulkAddQuoteTemplateMutation,
  useDeleteQuoteTemplateMutation,
  useGetSupplierProfileQuery,
  useUpdateSupplierProfileMutation,
  useGetSupplierTeamMembersQuery,
  useDeleteSupplierTeamMembersMutation,
} = api;
