/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";
import { transformPaginatedResponse } from "utils/api-utils";
import {
  transformPaginatedResponseWithCount,
  API_RESPONSE_LIMIT,
} from "utils/api-util";

import { baseQuery } from "./baseQuery";
import {
  updateDataAsync,
  setDataAsync,
  addDataAsync,
} from "../slices/pursuitSlice";
import { settlePromises } from "./queryAggregator";
import { ApiResponseHandler } from "./apiResponseHandler";
import { mapPriceAssumptionToApi } from "./typeMappings";

export const api = createApi({
  baseQuery,
  reducerPath: "pursuitsApi",
  tagTypes: [
    "ServiceByBuilding",
    "PursuitServices",
    "PursuitInfo",
    "PursuitCustomBuildingColumns",
    "ClientService",
    "PursuitKeyDates",
    "PursuitNotes",
    "CommunicationChannels",
    "SupplierInfo",
    "PursuitKeyNotes",
    "BuildingsInfo",
  ],
  endpoints: (build) => ({
    updateBaseline: build.mutation({
      query: ({ serviceByBuildingId, baselineCost, baselineCostCurrency }) => ({
        url: `service-by-building/${serviceByBuildingId}/`,
        method: "PATCH",
        body: {
          baseline_cost: baselineCost,
          baseline_cost_currency: baselineCostCurrency,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    addCustomPrice: build.mutation({
      query: ({ id, ...body }) => ({
        url: `client_rfxs/${id}/add-custom-price/`,
        method: "POST",
        body,
      }),
      keepUnusedDataFor: 0,
    }),
    updateCustomPrice: build.mutation({
      query: ({ id, ...body }) => ({
        url: `custom-prices/${id}`,
        method: "PATCH",
        body,
      }),
      keepUnusedDataFor: 0,
    }),
    getFirstPursuits: build.query({
      query: (limit = API_RESPONSE_LIMIT, offset = 0) =>
        `client_rfxs/?expand=client.industry&limit=${limit}&offset=${offset}&ordering=-date_updated`,
      transformResponse: transformPaginatedResponseWithCount,
      keepUnusedDataFor: 0, // if set this value as global then it doesn't cache data.
    }),
    getRestPursuits: build.query({
      async queryFn(arg, _, __, apiBaseQuery) {
        const { count, baseLimit = API_RESPONSE_LIMIT } = arg;
        const pages = Math.ceil(count / baseLimit);
        let limit;
        let offset;
        const promises = [];
        // page : 0 is already fetched by getFirstPursuits API, so start from page : 1
        for (let page = 1; page < pages; page += 1) {
          limit = page === pages - 1 ? count - page * baseLimit : baseLimit;
          offset = page * baseLimit;
          const promise = Promise.resolve(
            apiBaseQuery({
              url: `client_rfxs/?expand=client.industry&limit=${limit}&offset=${offset}&ordering=-date_updated`,
            })
          );
          promises.push(promise);
        }
        const responses = await Promise.allSettled(promises);
        const merged = [].concat(
          ...responses
            .filter((response) => !!response.value.data?.results)
            .map((response) => response.value.data.results)
        );
        const errors = [].concat(
          ...responses
            .filter((response) => !!response.value.error)
            .map((response) => response.value.error)
        );
        if (errors.length > 0) {
          return { error: errors };
        }
        return { data: merged };
      },
      transformResponse: transformPaginatedResponse,
      keepUnusedDataFor: 0, // if set this value as global then it doesn't cache data.
    }),
    getClientRfxs: build.query({
      query: (id) => `client_rfxs/${id}/`,
      transformResponse: transformPaginatedResponse,
      providesTags: ["PursuitInfo"],
    }),

    updateClientRfxs: build.mutation({
      query: ({ id, ...body }) => ({
        url: `client_rfxs/${id}/`,
        method: "PATCH",
        body,
      }),
      keepUnusedDataFor: 0,
    }),
    getCaltanaServices: build.query({
      query: (id) => `client-service/services/?client_rfx=${id}`,
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponse,
    }),
    getDeliveryModels: build.query({
      query: () => "delivery/",
      transformResponse: transformPaginatedResponse,
    }),

    getServices: build.query({
      query: (limit = 1000) => `services/?limit=${limit}`,
      providesTags: ["PursuitServices"],
      transformResponse: transformPaginatedResponse,
    }),

    updateServiceByBuildings: build.mutation({
      async queryFn(arg, _queryApi, _extraOptions, apiBaseQuery) {
        const serviceByBuildings = arg;
        const promises = [];
        try {
          serviceByBuildings.forEach((serviceByBuilding) => {
            const promise = Promise.resolve(
              apiBaseQuery({
                url: `service-by-building/${serviceByBuilding.service_by_building_id}/`,
                method: "PATCH",
                body: {
                  in_scope: serviceByBuilding.in_scope,
                  ...(!!serviceByBuilding.delivery_model && {
                    delivery_model: serviceByBuilding.delivery_model,
                  }),
                },
              })
            );
            promises.push(promise);
          });

          await Promise.allSettled(promises);
          return {
            data: [],
          };
        } catch (e) {
          return ApiResponseHandler.handleApiResponseException(
            e,
            "Failed to update serviceByBuilding."
          );
        }
      },
      invalidatesTags: ["ServiceByBuilding"],
    }),

    updateSelectedService: build.mutation({
      query: ({ id, pricingSource, buildingId, serviceId }) => ({
        url: `service-by-building/${id}/`,
        method: "PATCH",
        body: {
          pricing_source: pricingSource,
          building: buildingId,
          service: serviceId,
        },
      }),
    }),

    // bulk action for add/update assumptions
    // if assumption has id, updates assumption else adds assumption
    updateAssumptions: build.mutation({
      async queryFn(arg, _queryApi, _extraOptions, apiBaseQuery) {
        const { assumptions, field, serviceId } = arg;
        const promises = [];
        let reflectResponse = false;
        try {
          assumptions.forEach(
            ({ id, name, value, unit, source, service_by_building }) => {
              // check if BE response should be reflected to FE side. That is say, if all assumption actions are PATCH, no need to reflect response to FE.
              if (!id) {
                reflectResponse = true;
              }
              const promise = Promise.resolve(
                id
                  ? apiBaseQuery({
                      url: `assumption/${id}/`,
                      method: "PATCH",
                      body: {
                        unit,
                        value,
                        source,
                      },
                    })
                  : apiBaseQuery({
                      url: "assumption/",
                      method: "POST",
                      body: {
                        name,
                        value,
                        unit,
                        source,
                        service_by_building,
                      },
                    })
              );
              promises.push(promise);
            }
          );

          const result = await Promise.allSettled(promises);
          const converted = result.reduce((acc, cur) => {
            acc[cur.value.data.service_by_building] = cur.value.data;
            return acc;
          }, {});
          return {
            data: {
              data: converted,
              field,
              serviceId,
              reflectResponse,
            },
          };
        } catch (e) {
          return ApiResponseHandler.handleApiResponseException(
            e,
            "Failed adding/updating custom assumptions."
          );
        }
      },
      invalidatesTags: ["ServiceByBuilding", "BuildingsInfo"],
    }),
    deleteAssumption: build.mutation({
      query: (id) => ({
        url: `assumption/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ServiceByBuilding"],
    }),

    updateAllServicesByBuildings: build.mutation({
      queryFn: async (arg, _queryAPI, _extraOptions, apiBaseQuery) => {
        const { buildings } = arg;
        const promises = [];
        try {
          buildings.forEach(({ id }) => {
            promises.push(
              new Promise(
                apiBaseQuery({
                  url: `service-by-building/${id}/`,
                  method: "PATCH",
                })
              )
            );
          });

          const results = await Promise.all(promises);
          return results;
        } catch (e) {
          return ApiResponseHandler.handleApiResponseException(
            e,
            "Failed to update building services."
          );
        }
      },
    }),

    bulkUpdatePricingInputAssumptions: build.mutation({
      async queryFn(arg, _queryApi, _extraOptions, apiBaseQuery) {
        const { pricingInputs, field } = arg;
        if (pricingInputs.length === 0) {
          return {
            error: "No pricing input assumptions to update",
          };
        }
        const apiCaseAssumptions = pricingInputs.map((pricingInput) =>
          mapPriceAssumptionToApi(pricingInput)
        );
        try {
          const result = await apiBaseQuery({
            url: `pricing-input-assumptions/${apiCaseAssumptions[0].pricing_input}/bulk-update/`,
            method: "PATCH",
            body: apiCaseAssumptions,
          });

          return {
            data: {
              data: result.data,
              field,
            },
          };
        } catch (e) {
          return ApiResponseHandler.handleApiResponseException(
            e,
            "Failed to update updating pricing input assumptions."
          );
        }
      },
      invalidatesTags: ["BuildingsInfo"],
    }),

    updatePursuitInfo: build.mutation({
      query: ({ rfxId, ...body }) => ({
        url: `client_rfxs/${rfxId}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["PursuitInfo"],
    }),

    createPursuit: build.mutation({
      query: (body) => ({
        url: `client_rfxs/`,
        method: "POST",
        body,
      }),
    }),
    addBuildingColumn: build.mutation({
      query: ({ name, cleanedId, rfxId }) => ({
        url: `building_columns/`,
        method: "POST",
        body: {
          header_name: name,
          field: cleanedId,
          type: "string",
          editable: true,
          width: 180,
          client_rfx: rfxId,
        },
      }),
      invalidatesTags: ["PursuitCustomBuildingColumns"],
    }),
    updateBuildingColumn: build.mutation({
      query: ({ id, ...body }) => ({
        url: `building_columns/${id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["PursuitCustomBuildingColumns"],
    }),
    deleteBuildingColumn: build.mutation({
      query: (columnId) => ({
        url: `building_columns/${columnId}/`,
        method: "DELETE",
      }),
    }),

    getBuildingsColumns: build.query({
      query: (id) => `building_columns/?client_rfx=${id}`,
      providesTags: ["PursuitCustomBuildingColumns"],
      transformResponse: transformPaginatedResponse,
    }),

    getFirstBuildingsColumns: build.query({
      query: ({ rfxId, limit = API_RESPONSE_LIMIT, offset = 0 }) =>
        `building_columns/?client_rfx=${rfxId}&limit=${limit}&offset=${offset}`,
      providesTags: ["PursuitCustomBuildingColumns"],
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponseWithCount,
    }),
    getRestBuildingsColumns: build.query({
      async queryFn(arg, _, __, apiBaseQuery) {
        const { rfxId, count, baseLimit = API_RESPONSE_LIMIT } = arg;
        const pages = Math.ceil(count / baseLimit);
        let limit;
        let offset;
        const promises = [];
        // page : 0 is already fetched by getFirstBuildingsColumns API, so start from page : 1
        for (let page = 1; page < pages; page += 1) {
          limit = page === pages - 1 ? count - page * baseLimit : baseLimit;
          offset = page * baseLimit;
          const promise = Promise.resolve(
            apiBaseQuery({
              url: `building_columns/?client_rfx=${rfxId}&limit=${limit}&offset=${offset}`,
            })
          );
          promises.push(promise);
        }
        const responses = await settlePromises(promises);
        return responses;
      },
      keepUnusedDataFor: 0,
    }),

    updateAllActiveBid: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `client_rfxs/${id}/bid/active/apply-to-all/`,
        method: "POST",
        body: {
          pricing_source: payload.source,
          service_id: payload.service_id,
        },
      }),
    }),

    // currency conversion endpoints
    addConversionRate: build.mutation({
      query: (body) => ({
        url: `currency-conversion-rates/`,
        method: "POST",
        body,
      }),
    }),
    updateConversionRate: build.mutation({
      query: ({ id, ...body }) => ({
        url: `currency-conversion-rates/${id}/`,
        method: "PUT",
        body,
      }),
    }),

    getCurrencies: build.query({
      query: () => "client_rfxs/currencies/",
      transformResponse: transformPaginatedResponse,
    }),

    getCurrencyConversionRate: build.query({
      query: ({ rfxsId, baseCurrency, targetCurrency }) => ({
        url: `client_rfx/${rfxsId}/currencies/${baseCurrency}/conversion/${targetCurrency}/?open_exchange_rate=true`,
      }),
    }),
    getLatestConversionRates: build.query({
      async queryFn(arg, _queryApi, _extraOptions, apiBaseQuery) {
        const { rfxId, baseCurrencyCode, existingCurrencyCodes } = arg;
        const promises = [];
        try {
          existingCurrencyCodes.forEach((currencyCode) => {
            const promiseForCode = Promise.resolve(
              apiBaseQuery({
                url: `client_rfx/${rfxId}/currencies/${currencyCode}/conversion/${baseCurrencyCode}/?open_exchange_rate=true`,
              })
            );
            promises.push(promiseForCode);
          });

          const result = await Promise.allSettled(promises);
          const converted = result.map((record, k) => ({
            source: existingCurrencyCodes[k],
            target: baseCurrencyCode,
            client_rfx: rfxId,
            rate: record?.value?.data?.conversion_rate || 0,
          }));
          return {
            data: converted,
          };
        } catch (e) {
          return ApiResponseHandler.handleApiResponseException(
            e,
            "Failed to get conversion rates."
          );
        }
      },
    }),
    getCurrencyConversionRates: build.query({
      query: (rfxId) => `currency-conversion-rates/${rfxId}/`,
      keepUnusedDataFor: 0,
      transformResponse: transformPaginatedResponse,
    }),
    getAllCurrencyConversionRates: build.query({
      async queryFn(arg, _, __, apiBaseQuery) {
        const rfxId = arg;
        try {
          const response = await apiBaseQuery("currency-conversion-rates/");
          const transformResponse = transformPaginatedResponse(response?.data);
          const filteredRates = transformResponse?.filter(
            (rateData) => rateData.client_rfx === rfxId
          );
          return {
            data: filteredRates,
          };
        } catch (e) {
          return ApiResponseHandler.handleApiResponseException(
            e,
            "Failed to get conversion rates."
          );
        }
      },
      keepUnusedDataFor: 0,
    }),
    getClientServices: build.query({
      query: (id) =>
        `client-service/?expand=services,client_rfx&client_rfx=${id}`,
      keepUnusedDataFor: 0,
      providesTags: ["ClientService"],
      transformResponse: transformPaginatedResponse,
    }),

    createServices: build.mutation({
      async queryFn(arg, _, __, apiBaseQuery) {
        const services = arg;
        const promises = [];
        try {
          services.forEach(({ rfxId, clientService }) => {
            const promise = Promise.resolve(
              apiBaseQuery({
                url: "client-service/",
                method: "POST",
                body: {
                  in_scope: true,
                  delivery_model: null,
                  assumption: null,
                  client_rfx: rfxId,
                  service_name: clientService,
                  services: [],
                },
              })
            );
            promises.push(promise);
          });
          const results = await Promise.all(promises);
          return {
            data: results.map((res) => res.data),
          };
        } catch (e) {
          return ApiResponseHandler.handleApiResponseException(
            e,
            "Failed to create services."
          );
        }
      },
    }),

    updateServices: build.mutation({
      async queryFn(arg, _, __, apiBaseQuery) {
        const services = arg;
        const promises = [];
        try {
          services.forEach(({ id, ...body }) => {
            const promise = Promise.resolve(
              apiBaseQuery({
                url: `client-service/${id}/`,
                method: "PATCH",
                body,
              })
            );
            promises.push(promise);
          });
          const results = await Promise.all(promises);
          return {
            data: results.map((res) => res.data),
          };
        } catch (e) {
          return ApiResponseHandler.handleApiResponseException(
            e,
            "Failed to update services."
          );
        }
      },
      invalidatesTags: ["ClientService"],
    }),

    deleteServices: build.mutation({
      async queryFn(arg, _, __, apiBaseQuery) {
        const { serviceIds = [] } = arg;
        const promises = [];
        try {
          serviceIds.forEach((id) => {
            const promise = Promise.resolve(
              apiBaseQuery({
                url: `client-service/${id}/`,
                method: "DELETE",
              })
            );
            promises.push(promise);
          });
          await Promise.all(promises);
          return { data: [] };
        } catch (e) {
          return ApiResponseHandler.handleApiResponseException(
            e,
            "Failed to delete services."
          );
        }
      },
      invalidatesTags: ["ClientService"],
    }),

    // pursuit key dates
    createPursuitDate: build.mutation({
      async queryFn(arg, _api, __extraOptions, apiBaseQuery) {
        const { client_rfx, id, ...rest } = arg;
        const response = await apiBaseQuery({
          url: `client_rfxs/${client_rfx}/schedules/`,
          method: "POST",
          client_rfx,
          body: { client_rfx, ...rest },
        });
        const successResponseHandler = (data) => {
          _api.dispatch(addDataAsync(data));
          return { data };
        };
        return ApiResponseHandler.handleApiResponse(
          response,
          successResponseHandler,
          "Failed to create a pursuit key date"
        );
      },
    }),
    updatePursuitDate: build.mutation({
      async queryFn(arg, _api, __extraOptions, apiBaseQuery) {
        const { client_rfx, id, ...rest } = arg;
        const response = await apiBaseQuery({
          url: `client_rfxs/${client_rfx}/schedules/${id}/`,
          method: "PUT",
          client_rfx,
          body: { client_rfx, ...rest },
        });
        const successResponseHandler = (data) => {
          _api.dispatch(updateDataAsync(data));
          return { data };
        };
        return ApiResponseHandler.handleApiResponse(
          response,
          successResponseHandler,
          "Failed to update a pursuit key date"
        );
      },
    }),
    deletePursuitDate: build.mutation({
      query: ({ client_rfx, id }) => ({
        url: `client_rfxs/${client_rfx}/schedules/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["PursuitKeyDates"],
    }),
    getPursuitDates: build.query({
      async queryFn(arg, _api, __extraOptions, apiBaseQuery) {
        const response = await apiBaseQuery(`client_rfxs/${arg}/schedules/`);
        const successResponseHandler = (data) => {
          _api.dispatch(setDataAsync(data.results));
          return { data: data.results };
        };
        return ApiResponseHandler.handleApiResponse(
          response,
          successResponseHandler,
          "Failed to fetch pursuit key dates"
        );
      },
      keepUnusedDataFor: 0,
      providesTags: ["PursuitKeyDates"],
      transformResponse: transformPaginatedResponse,
    }),
    createPursuitCommunicationChannels: build.mutation({
      query: ({ id }) => ({
        url: `client_rfxs/${id}/communication_channels/`,
        method: "POST",
      }),
    }),
    getCommunicationChannels: build.query({
      query: ({ id, channelType }) => ({
        url: `client_rfxs/${id}/communication_channels/?channel_type=${channelType}`,
        method: "POST",
      }),
    }),

    // pursuit notes
    createPursuitNote: build.mutation({
      async queryFn(arg, _api, __extraOptions, apiBaseQuery) {
        const { client_rfx, id, ...rest } = arg;
        const response = await apiBaseQuery({
          url: `client_rfxs/${client_rfx}/notes/`,
          method: "POST",
          client_rfx,
          body: { client_rfx, ...rest },
        });

        const successResponseHandler = (data) => {
          _api.dispatch(addDataAsync(data));
          return { data };
        };
        return ApiResponseHandler.handleApiResponse(
          response,
          successResponseHandler,
          "Failed to create a pursuit note"
        );
      },
    }),
    updatePursuitNote: build.mutation({
      async queryFn(arg, _api, __extraOptions, apiBaseQuery) {
        const { client_rfx, id, ...rest } = arg;
        const response = await apiBaseQuery({
          url: `client_rfxs/${client_rfx}/notes/${id}/`,
          method: "PUT",
          client_rfx,
          body: { client_rfx, ...rest },
        });

        const successResponseHandler = (data) => {
          _api.dispatch(updateDataAsync(data));
          return { data };
        };
        return ApiResponseHandler.handleApiResponse(
          response,
          successResponseHandler,
          "Failed to update a pursuit note"
        );
      },
    }),
    deletePursuitNote: build.mutation({
      query: ({ client_rfx, id }) => ({
        url: `client_rfxs/${client_rfx}/notes/${id}/`,
        method: "DELETE",
      }),
    }),
    getPursuitNotes: build.query({
      async queryFn(arg, _api, __extraOptions, apiBaseQuery) {
        const response = await apiBaseQuery(`client_rfxs/${arg}/notes/`);

        const successResponseHandler = (data) => {
          _api.dispatch(setDataAsync([]));
          return { data: data.results };
        };
        return ApiResponseHandler.handleApiResponse(
          response,
          successResponseHandler,
          "Failed to fetch pursuit notes"
        );
      },
      keepUnusedDataFor: 0,
      providesTags: ["PursuitKeyNotes"],
      transformResponse: transformPaginatedResponse,
    }),
  }),
});

export const {
  useGetFirstPursuitsQuery,
  useGetRestPursuitsQuery,
  useGetClientRfxsQuery,

  useUpdateClientRfxsMutation,
  useGetCaltanaServicesQuery,
  useGetDeliveryModelsQuery,

  useUpdateServiceByBuildingsMutation,
  useDeleteAssumptionMutation,
  useUpdateAssumptionsMutation,
  useBulkUpdatePricingInputAssumptionsMutation,
  useUpdateBaselineMutation,
  useAddCustomPriceMutation,
  useUpdateCustomPriceMutation,

  useUpdatePursuitInfoMutation,
  useCreatePursuitMutation,
  useAddBuildingColumnMutation,
  useUpdateBuildingColumnMutation,
  useDeleteBuildingColumnMutation,

  useGetBuildingsColumnsQuery,
  useGetFirstBuildingsColumnsQuery,
  useGetRestBuildingsColumnsQuery,

  useGetServicesQuery,

  useUpdateAllActiveBidMutation,

  useGetClientServicesQuery,
  useCreateServicesMutation,
  useUpdateServicesMutation,
  useDeleteServicesMutation,

  useUpdateSelectedServiceMutation,

  useGetCurrenciesQuery,
  useGetCurrencyConversionRatesQuery,
  useLazyGetCurrencyConversionRateQuery,
  useLazyGetLatestConversionRatesQuery,
  useAddConversionRateMutation,
  useUpdateConversionRateMutation,
  useGetAllCurrencyConversionRatesQuery,

  useCreatePursuitDateMutation,
  useUpdatePursuitDateMutation,
  useDeletePursuitDateMutation,
  useGetPursuitDatesQuery,
  useCreatePursuitCommunicationChannelsMutation,
  useGetCommunicationChannelsQuery,

  useCreatePursuitNoteMutation,
  useUpdatePursuitNoteMutation,
  useDeletePursuitNoteMutation,
  useGetPursuitNotesQuery,
} = api;
