import { ListResponse } from "store/api/types";

export const getErrorMessage = (actionName: string, response) => {
  // eslint-disable-next-line no-console
  console.error(response);
  return `Error in ${actionName}!`;
};

export const transformPaginatedResponse = <T>(
  response: ListResponse<T> | T[]
) => ("results" in response ? response?.results : response);
