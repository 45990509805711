export const STORAGE_KEY_USER_DATA = "user";
export const STORAGE_KEY_TOKEN = "token";
export const STORAGE_KEY_PAGE_TITLE = "current-page-title";

export const getStoreItem = (key) => {
  const storage = window.localStorage;
  const item = storage.getItem(key);
  if (!item) return null;

  try {
    return JSON.parse(item);
  } catch (e) {
    return JSON.parse(JSON.stringify(item));
  }
};

export const saveStoreItem = (storage, key, value) =>
  storage.setItem(key, JSON.stringify(value));

export const deleteStoreItem = (storage, key) => storage.removeItem(key);

export function loadStoreValue(itemKey) {
  try {
    const serializedState = localStorage.getItem(itemKey);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export function saveStoreValue(itemKey, state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(itemKey, serializedState);
  } catch (e) {
    // ignore
  }
}

export function removeStoreValue(itemKey) {
  localStorage.removeItem(itemKey);
}

export const clearStoreValues = () => {
  localStorage.clear();
};
