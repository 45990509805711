import { FetchArgs, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import server from "config/server";
import { clearUserDataAsync } from "store/slices/authUserSlice";
import { loadStoreValue, STORAGE_KEY_TOKEN } from "utils/session-util";

export interface BaseQueryExtraOptions {
  response: {
    status: number;
    statusText: string;
    ok: boolean;
    bodyUsed: boolean;
    headers: Headers;
  };
}

export const baseQuery = retry(
  async (
    args: string | FetchArgs,
    api,
    extraOptions: BaseQueryExtraOptions
  ) => {
    const result = await fetchBaseQuery({
      baseUrl: `${server.HOST}api/v1/`,
      prepareHeaders: (headers) => {
        const {
          authUserSlice: { token },
        } = api.getState() as { authUserSlice: { token: string } };
        // check if localStorage token is valid or not
        const tokenFromLocalStorage = loadStoreValue(
          STORAGE_KEY_TOKEN
        ) as string;
        if (!tokenFromLocalStorage) {
          api.dispatch(clearUserDataAsync());
        }
        if (token) {
          headers.set("Authorization", `Token ${token}`);
          headers.set("Content-Type", "application/json");
        }
        return headers;
      },
    })(args, api, extraOptions);

    // if any error, need to try to call this api again
    if (
      Number(result.error?.status) < 500 &&
      Number(result.error?.status) >= 400
    ) {
      retry.fail(result.error);
    }
    return result;
  },
  {
    maxRetries: 2,
  }
);
