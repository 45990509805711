import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: undefined, // can be undefined: initial state, true: loading now,  false: loading ended
  gridApi: undefined,
};

export const tableStateSlice = createSlice({
  name: "tableState",
  initialState,
  reducers: {
    resetLoading: (state) => {
      state.isLoading = undefined;
    },
    updateLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateGridApi: (state, action) => {
      state.gridApi = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetLoading, updateLoading, updateGridApi } = tableStateSlice.actions;
export const resetLoadingAsync = (payload) => (dispatch) => dispatch(resetLoading(payload));
export const updateLoadingAsync = (payload) => (dispatch) => dispatch(updateLoading(payload));
export const updateGridApiAsync = (payload) => (dispatch) => dispatch(updateGridApi(payload));

export const currentLoadingState = (state) => state.tableStateSlice.isLoading;
export const currentGridApi = (state) => state.tableStateSlice.gridApi;

export default tableStateSlice;
