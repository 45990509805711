/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";
import { getErrorMessage, transformPaginatedResponse } from "utils/api-utils";

import { baseQuery } from "./baseQuery";
import { ApiResponseHandler } from "./apiResponseHandler";
import { HttpStatusCode } from "./httpStatusCodes";

export type FormulaDefinition = {
  type:
    | "arithmetic"
    | "building_column"
    | "formula"
    | "number"
    | "pricing_input"
    | "rate_card";
};
export type FormulaDefinitionReference = FormulaDefinition & {
  type: "pricing_input" | "formula";
  id: number;
};
export type FormulaDefinitionNumber = FormulaDefinition & {
  type: "number";
  value: number;
};
export type FormulaDefinitionArithmetic = FormulaDefinition & {
  type: "arithmetic";
  operator: string;
  elements: FormulaDefinition[];
};

export type FormulaDefinitionRateCardFilter = {
  data_type: "range" | "string" | "url";
  expansion: object;
  filter_by: number | string;
  filter_type: string;
  rate_card_column_id: number;
  rate_card_column_value?: string;
  type: "rate_card_column";
};

export type FormulaDefinitionRateCard = FormulaDefinition & {
  type: "rate_card";
  id: number;
  output_field: string;
  output_id: number;
  output_type: string;
  filters: FormulaDefinitionRateCardFilter[];
};

// Array consists of a string and 2 numbers (as strings).
//  ex: ["input", "1", "2"]
// or as string, number, string
//  ex: ["building", "1", "building_type"]
// seems to only be present in "rate_card" type
export type FormulaVariableCondition = string[];

export type FormulaVariable = {
  id: number;
  conditions: FormulaVariableCondition[];
  formula: number;
  source: string;
  target: string;
  type: "number" | "operator" | "formula" | "rate_card" | "input";
};

export type Formula = {
  id: number;
  currency: string;
  definition: FormulaDefinition;
  name: string;
  parent?: number;
  primary: boolean;
  service: number;
  variables: FormulaVariable[];
};

export type GetFormulasParams = {
  serviceId: number;
  parentId?: number;
};

export const api = createApi({
  baseQuery,
  reducerPath: "formulasApi",
  tagTypes: ["ServiceFormula"],
  endpoints: (build) => ({
    // Formulas
    getFormulas: build.query<Formula[], GetFormulasParams>({
      query: ({ serviceId, parentId }) => {
        const parent = parentId ? `&parent=${parentId}` : "";
        return `formulas/?service=${serviceId}${parent}`;
      },
      transformResponse: transformPaginatedResponse,
      providesTags: ["ServiceFormula"],
    }),

    addFormula: build.mutation<Omit<Formula, "id">, Formula>({
      query: (body) => ({
        url: "formulas/",
        method: "POST",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_201_CREATED,
            response.status,
            "Failed to add formula!",
            () => getErrorMessage("adding a formula", meta)
          ),
      }),
      invalidatesTags: ["ServiceFormula"],
    }),

    updateFormula: build.mutation<Formula, Formula>({
      query: ({ id, ...body }) => ({
        url: `formulas/${id}/`,
        method: "PATCH",
        body,
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_200_OK,
            response.status,
            "Failed to update formula!",
            () => getErrorMessage("updating a formula", meta)
          ),
      }),
      invalidatesTags: ["ServiceFormula"],
    }),

    deleteFormula: build.mutation({
      query: (id: number) => ({
        url: `formulas/${id}/`,
        method: "DELETE",
        validateStatus: (response, meta) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_204_NO_CONTENT,
            response.status,
            "Failed to delete formula!",
            () => getErrorMessage("deleting a formula", meta)
          ),
      }),
      invalidatesTags: ["ServiceFormula"],
    }),
  }),
});

export const {
  useGetFormulasQuery,
  useAddFormulaMutation,
  useUpdateFormulaMutation,
  useDeleteFormulaMutation,
} = api;
