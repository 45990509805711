export function getEnv() {
  const currentBranch = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
    return "production";
  }
  if (
    process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" &&
    currentBranch === "main"
  ) {
    return "dev";
  }
  if (
    process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" &&
    currentBranch === "main-preview"
  ) {
    return "preview";
  }

  return currentBranch;
}
