// default host to the api server running in same environment as front-end
const server = {
  HOST: process.env.NEXT_PUBLIC_HOST_URL || "/",
  SITE_URL:
    (process.env.NEXT_PUBLIC_SITE_URL?.endsWith("/")
      ? process.env.NEXT_PUBLIC_SITE_URL
      : `${process.env.NEXT_PUBLIC_SITE_URL ?? ""}/`) ??
    `https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}/`,
};

export default server;
