export const settlePromises = async (openPromises) => {
  const responses = await Promise.allSettled(openPromises);

  const errors = [].concat(
    ...responses
      .filter((response) => !!response.value.error)
      .map((response) => response.value.error)
  );
  if (errors.length > 0) {
    return { error: errors };
  }
  const data = [].concat(
    ...responses
      .filter(
        (response) => !!response.value.data?.results || !!response.value.data
      )
      .map((response) => response.value.data?.results || response.value.data)
  );
  return { data };
};
