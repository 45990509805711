/* eslint-disable camelcase */
import { mapKeys, camelCase } from "lodash";
import {
  ApiBuilding,
  ApiClientRfx,
  ApiPriceAssumption,
  ApiUnitScope,
} from "./apiTypes";
import { PricingProject } from "./projects";
import { Building, PriceAssumption, UnitScope } from "./types";

function convertToCamelCase(obj: Record<string, any>): Record<string, any> {
  return mapKeys(obj, (value, key) => camelCase(key));
}

export const mapApiClientRfxToPricingProject = ({
  id,
  client_name,
  organization: { name },
  client_industry,
  region,
  lead_region,
  contract_term,
  submittal_phase,
  project_overview,
  portfolio_overview,
  terms,
  business_conditions,
  duration_of_offer,
  generation_of_outsourcing,
  next_milestone,
  ...rest
}: ApiClientRfx): PricingProject => ({
  id,
  clientName: client_name,
  ownerName: name,
  clientIndustry: client_industry,
  regions: region,
  leadRegion: lead_region,
  contractTerm: contract_term,
  submittalPhase: submittal_phase,
  overview: project_overview,
  portfolioOverview: portfolio_overview,
  terms,
  businessConditions: business_conditions,
  offerDuration: duration_of_offer,
  generationOutsourced: generation_of_outsourcing,
  nextMilestone: next_milestone,
  ...rest,
});

export const mapPricingProjectToApiClientRfx = ({
  id,
  clientName,
  clientIndustry,
  regions,
  leadRegion,
  contractTerm,
  submittalPhase,
  overview,
  portfolioOverview,
  terms,
  businessConditions,
  offerDuration,
  generationOutsourced,
  nextMilestone,
  ...rest
}: PricingProject): Partial<ApiClientRfx> => ({
  id,
  client_name: clientName,
  client_industry: clientIndustry,
  region: regions,
  lead_region: leadRegion,
  contract_term: contractTerm,
  submittal_phase: submittalPhase,
  project_overview: overview,
  portfolio_overview: portfolioOverview,
  terms,
  business_conditions: businessConditions,
  duration_of_offer: offerDuration,
  generation_of_outsourcing: generationOutsourced,
  next_milestone: nextMilestone,
  ...rest,
});

export const mapApiPriceAssumption = ({
  id,
  name,
  type,
  source,
  service_by_building,
  pricing_input,
  data,
}: ApiPriceAssumption): PriceAssumption => ({
  id,
  name,
  type,
  source,
  serviceByBuilding: service_by_building,
  pricingInput: pricing_input,
  data,
});

export const mapApiUnitScope = ({
  id,
  service_id,
  building_id,
  estimate,
  pricing_assumptions,
  in_scope,
  delivery_model,
}: ApiUnitScope): UnitScope => ({
  id,
  serviceId: service_id,
  buildingId: building_id,
  inScope: in_scope,
  deliveryModel: delivery_model,
  estimate,
  pricingAssumptions: pricing_assumptions.map(mapApiPriceAssumption),
});

export const mapApiBuilding = (apiBuilding: ApiBuilding): Building =>
  convertToCamelCase(apiBuilding) as Building;

export const mapPriceAssumptionToApi = (
  priceAssumption: PriceAssumption
): Partial<ApiPriceAssumption> => ({
  id: priceAssumption.id,
  name: priceAssumption.name,
  type: priceAssumption.type,
  source: priceAssumption.source,
  service_by_building: priceAssumption.serviceByBuilding,
  pricing_input: priceAssumption.pricingInput,
  data: priceAssumption.data,
});
