import { ListResponse } from "store/api/types";
import { loadStoreValue, STORAGE_KEY_TOKEN } from "utils/session-util";

/**
 *
 * @param path
 * @param authToken
 * @param options
 * @returns
 */
export function fetchClient(path: string, options?: RequestInit) {
  const tokenFromLocalStorage = loadStoreValue(STORAGE_KEY_TOKEN) as string;
  if (!tokenFromLocalStorage) {
    return Promise.reject(new Error("No token found in local storage"));
  }
  const hostUrl = process.env.NEXT_PUBLIC_HOST_URL;
  const url = path.startsWith("http") ? path : `${hostUrl}${path}`;

  const headers = {
    ...options?.headers,
    Authorization: tokenFromLocalStorage.startsWith("Token ")
      ? tokenFromLocalStorage
      : `Token ${tokenFromLocalStorage}`,
    "Content-Type": "application/json",
  };
  const newOptions = {
    ...options,
    headers,
  } as RequestInit;

  const retryFetch = async (attempt = 1): Promise<Response> => {
    try {
      const response = await fetch(url, newOptions);
      if (!response.ok && attempt < 3) {
        const delay = Math.pow(2, attempt) * 100; // exponential backoff
        await new Promise((resolve) => setTimeout(resolve, delay));
        return retryFetch(attempt + 1);
      }
      return response;
    } catch (error) {
      if (attempt < 3) {
        const delay = Math.pow(2, attempt) * 100; // exponential backoff
        await new Promise((resolve) => setTimeout(resolve, delay));
        return retryFetch(attempt + 1);
      }
      throw error;
    }
  };

  return retryFetch();
}

export async function fetchAll<T>(
  baseUrl: string,
  callback?: (items: T[]) => void
): Promise<T[]> {
  async function fetchItems(url: string, items: T[]): Promise<T[]> {
    if (!url) return items;

    const response: ListResponse<T> = await fetchClient(url).then(
      (res) => res.json() as Promise<ListResponse<T>>
    );
    callback?.(response.results);
    const newItems = [...items, ...response.results];

    return fetchItems(response.next, newItems);
  }

  return fetchItems(baseUrl, []);
}
