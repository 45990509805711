// instead of using localStorage variables over the project, it needs to store them into redux
// By doing this, we can sync localStorage with redux state and avoid to add repeatable codes(useEffect to check whether window is loaded or not)
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { datadogRum } from "@datadog/browser-rum";
import {
  saveStoreValue,
  STORAGE_KEY_USER_DATA,
  STORAGE_KEY_TOKEN,
  removeStoreValue,
  loadStoreValue,
} from "../../utils/session-util";
import server from "../../config/server";
import { loginAPI } from "./loginSlice";

const initialState = {
  data: null,
  token: null,
  loading: false,
};

export const logoutAPI = createAsyncThunk("logoutUser", async () => {
  const tokenFromLocalStorage = loadStoreValue(STORAGE_KEY_TOKEN);
  const response = await fetch(`${server.HOST}api/v1/logout/`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${tokenFromLocalStorage}`,
    },
    referrer: server.HOST,
    referrerPolicy: "origin-when-cross-origin",
    mode: "cors",
    credentials: "include",
  });
  if (response.status !== 200) {
    return {
      error: "Failed to logout this user!",
    };
  }
  return {};
});

export const signUpAPI = createAsyncThunk("signUpUser", async (userData) => {
  const body = JSON.stringify(userData);
  const response = await fetch(`${server.HOST}api/v1/suppliers/signup/`, {
    method: "POST",
    body,
    headers: {
      "content-type": "application/json",
    },
    referrer: server.HOST,
    referrerPolicy: "origin-when-cross-origin",
    mode: "cors",
    credentials: "include",
  });
  const json = await response.json();
  if (response.status === 400) {
    return {
      error: "User with provided email has not been invited to join Caltana.",
    };
  }
  if (response.status !== 200) {
    return {
      error: "Failed to sign up this user!",
    };
  }
  return json;
});

const clearLocalUserData = (state) => {
  state.data = null;
  state.token = null;
  removeStoreValue(STORAGE_KEY_USER_DATA);
  removeStoreValue(STORAGE_KEY_TOKEN);
};

const setUserData = (user, state, token) => {
  state.data = user;
  state.token = token;
  // store the response to localStorage
  saveStoreValue(STORAGE_KEY_USER_DATA, user);
  saveStoreValue(STORAGE_KEY_TOKEN, token);
};

const startPendo = (user) => {
  // Pendo installation guide: https://support.pendo.io/hc/en-us/articles/360046272771
  const env = process.env.NEXT_PUBLIC_VERCEL_ENV || "dev";
  // eslint-disable-next-line no-undef
  pendo.initialize({
    visitor: {
      id: `${user.id}-${env}`, // userId needs to be unique across environments
      email: user.email,
    },
    account: {
      id: `${user.organization?.id}-org-${env}`,
      name: `${user.organization?.name}-${env}`,
    },
  });
};

const setLoadingState = (state) => {
  state.loading = true;
  state.data = null;
  state.token = null;
};

export const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    clearUserData: (state) => {
      clearLocalUserData(state);
      if ("removeUser" in datadogRum) {
        datadogRum.removeUser();
      }
    },
    acceptTerms: (state) => {
      state.data.is_tos_accepted = true;
      saveStoreValue(STORAGE_KEY_USER_DATA, state.data);
    },
  },
  extraReducers: {
    [loginAPI.pending]: (state) => {
      setLoadingState(state);
    },
    [loginAPI.fulfilled]: (state, { payload }) => {
      const { user, token, error } = payload;
      state.loading = false;
      if (error) {
        clearLocalUserData(state);
        return;
      }
      setUserData(user, state, token);
      // startPendo(user);
    },
    [logoutAPI.pending]: (state) => {
      setLoadingState(state);
    },
    [logoutAPI.fulfilled]: (state) => {
      clearLocalUserData(state);
      datadogRum.removeUser();
      state.loading = false;
    },
    [signUpAPI.pending]: (state) => {
      setLoadingState(state);
    },
    [signUpAPI.fulfilled]: (state, { payload }) => {
      const { login, error } = payload;
      state.loading = false;
      if (error) {
        clearLocalUserData(state);
        return;
      }
      const { user, token } = login;
      setUserData(user, state, token);
      startPendo(user);
    },
  },
});

const { clearUserData } = authUserSlice.actions;
export const clearUserDataAsync = () => (dispatch) => dispatch(clearUserData());
export const selectUserData = (state) => state.authUserSlice;

const { acceptTerms } = authUserSlice.actions;
export const acceptTermsAsync = (dispatch) => dispatch(acceptTerms());
export default authUserSlice.reducer;
