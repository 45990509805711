import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { fetchClient } from "components/fetchClient";

const startSignInSession = (user) => {
  datadogRum.setUser({
    id: user.id,
    email: user.email,
    name: user.name,
    organization: user.organization?.name,
  });
};

// ref: https://github.com/ivandotv/nextjs-client-signin-logic/blob/main/src/components/AuthGuard.tsx
export default function AuthGuard({ children }) {
  const [user, setUser] = useState(null);
  const router = useRouter();

  useEffect(() => {
    fetchClient("api/v1/profile/")
      .then((res) => res.json())
      .then((data) => {
        setUser(data);
      })
      .catch(() => {
        if (
          router.pathname !== "/auth/login" &&
          router.pathname !== "/logout"
        ) {
          window.location.href = "/logout";
        }
      });
  }, [router]);

  useEffect(() => {
    if (!router.isReady || !user) return;

    if (
      user.is_supplier &&
      "is_tos_accepted" in user &&
      !user.is_tos_accepted &&
      router.pathname !== "/accept-tos"
    ) {
      router.push({
        pathname: "/accept-tos",
      });
    }
  }, [router, user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    startSignInSession(user);
  }, [user]);

  // if auth initialized with a valid user show protected page
  if (user) {
    return <>{children}</>;
  }

  /* otherwise don't return anything, will do a redirect from useEffect */
  return null;
}
