import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export const api = createApi({
  baseQuery,
  reducerPath: "usersApi",
  tagTypes: [],
  endpoints: (build) => ({
    resetRequest: build.mutation({
      query: (email) => ({
        url: `reset_password/`,
        method: "POST",
        body: { email },
      }),
    }),

    resetConfirmRequest: build.mutation({
      query: ({ password, token }) => ({
        url: `reset_password/confirm/`,
        method: "POST",
        body: { password, token },
      }),
    }),
  }),
});

export const { useResetRequestMutation, useResetConfirmRequestMutation } = api;
