import { createAsyncThunk } from "@reduxjs/toolkit";
import server from "config/server";
import { LoginApiResponse, UserSelector } from "store/api/types";

export const loginAPI = createAsyncThunk<
  UserSelector,
  { email: string; password: string },
  { rejectValue: { error: string } }
>("loginUser", async ({ email: username, password }, { rejectWithValue }) => {
  const body = JSON.stringify({ username, password });
  const response = await fetch(`${server.HOST}api/v1/login/`, {
    method: "POST",
    body,
    headers: {
      "content-type": "application/json",
    },
    referrer: server.HOST,
    referrerPolicy: "origin-when-cross-origin",
    mode: "cors",
    credentials: "include",
  });
  const json = (await response.json()) as LoginApiResponse;
  if (response.status !== 200) {
    return rejectWithValue({
      error: json.non_field_errors?.[0] ?? "Login error",
    });
  }
  if (!window.location.hostname.endsWith(".caltana.com")) {
    document.cookie = `auth_token=${json.token}; path=/; secure; SameSite=Strict`;
  }

  return json as UserSelector;
});
