export const API_RESPONSE_LIMIT = 100;
export const PREFETCH_API_RESPONSE_LIMIT = 10;
export const postQuery = ({ url, data }) => ({
  url,
  method: "POST",
  body: data,
});

export const transformPaginatedResponseWithCount = (response) => ({
  count: response?.count,
  results: response?.results,
});

/**
 * We are using the browser's Intl.DateTimeFormat API to format dates.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#Syntax
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
 */

// TODO: Use the browser's locale to format dates.
const locale = "en-US";

export const convertToAPIDate = (date) =>
  date ? new Date(date).toISOString().slice(0, 10) : null;

export const convertToAPIDateTime = (date) =>
  date ? new Date(date).toISOString() : null;

export const convertAPIDateTimeToReportDateTime = (date) => {
  const dateTimeOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };
  if (date === "") return "";
  if (!date) return null;
  try {
    return Intl.DateTimeFormat(locale, dateTimeOptions).format(new Date(date));
  } catch (e) {
    return null;
  }
};

export const convertAPIDateTimeToReportDate = (date) => {
  date = date && date.length <= 10 ? `${date}T12:00:00.000Z` : date;
  const dateTimeOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  if (date === "") return "";
  if (!date) return null;
  try {
    return Intl.DateTimeFormat(locale, dateTimeOptions).format(new Date(date));
  } catch (e) {
    return null;
  }
};
