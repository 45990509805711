import { createApi } from "@reduxjs/toolkit/query/react";
import { transformPaginatedResponse } from "utils/api-utils";
import { baseQuery } from "./baseQuery";
import { ListResponse } from "./types";
import { ApiClientRfx, ApiClientService, ApiService } from "./apiTypes";
import { mapApiClientRfxToPricingProject } from "./typeMappings";

export type PricingProject = {
  id: number;
  clientName: string;
  ownerName: string;
  clientIndustry: string;
  regions: string[];
  leadRegion: string;
  contractTerm: number;
  submittalPhase: string;
  overview: string;
  portfolioOverview: string;
  terms: string;
  businessConditions: string;
  offerDuration: string;
  generationOutsourced: string;
  nextMilestone: string;
};

export type Service = {
  id: number;
  name: string;
  description: string;
  parent: number;
};

const mapApiServiceToService = (apiService: ApiService): Service => ({
  id: apiService.id,
  name: apiService.value,
  description: apiService.description,
  parent: apiService.parent,
});

const mapApiClientServiceToClientService = (
  apiClientService: ApiClientService
): ClientService => ({
  id: apiClientService.id,
  project: mapApiClientRfxToPricingProject(apiClientService.client_rfx),
  services: apiClientService.services.map(mapApiServiceToService),
  inScope: apiClientService.in_scope,
  name: apiClientService.service_name || "",
});

export type ClientService = {
  id: number;
  name: string;
  project: PricingProject;
  services: Service[];
  inScope: boolean;
};

export const api = createApi({
  baseQuery,
  reducerPath: "projectsApi",
  tagTypes: ["ClientService", "ProjectInfo"],
  endpoints: (build) => ({
    getClientServices: build.query<ClientService[], string>({
      query: (id) =>
        `client-service/?expand=services,client_rfx&client_rfx=${id}`,
      keepUnusedDataFor: 0,
      providesTags: ["ClientService"],
      transformResponse: (response: ListResponse<ApiClientService>) =>
        transformPaginatedResponse(response).map(
          mapApiClientServiceToClientService
        ),
    }),
    getProject: build.query<PricingProject, string>({
      query: (id) => `client_rfxs/${id}/`,
      providesTags: ["ProjectInfo"],

      transformResponse: (res: ApiClientRfx) =>
        mapApiClientRfxToPricingProject(res),
    }),
    updateProject: build.mutation<
      PricingProject,
      { rfxId: string } & ApiClientRfx
    >({
      query: ({ rfxId, ...body }) => ({
        url: `client_rfxs/${rfxId}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ProjectInfo"],
      transformResponse: (res: ApiClientRfx) =>
        mapApiClientRfxToPricingProject(res),
    }),
    patchProject: build.mutation<
      PricingProject,
      { rfxId: string } & Partial<ApiClientRfx>
    >({
      query: ({ rfxId, ...body }) => ({
        url: `client_rfxs/${rfxId}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["ProjectInfo"],

      transformResponse: (res: ApiClientRfx) =>
        mapApiClientRfxToPricingProject(res),
    }),
  }),
});

export const {
  useGetClientServicesQuery,
  useGetProjectQuery,
  usePatchProjectMutation,
  useUpdateProjectMutation,
} = api;
