import { createSlice } from "@reduxjs/toolkit";
import { saveStoreValue, STORAGE_KEY_PAGE_TITLE } from "../../utils/session-util";

const initialState = {
  history: [],
  pageTitle: ''
};

export const historySlice = createSlice({
  name: "linkHistory",
  initialState,
  reducers: {
    updateHistory: (state, action) => {
      const { level } = action.payload;
      if (level === 0) {
        // if service level
        state.history = [action.payload];
      } else {
        state.history[level] = action.payload;
      }
    },
    updateTitle: (state, action) => {
      state.pageTitle = action.payload;
      saveStoreValue(STORAGE_KEY_PAGE_TITLE, action.payload);
    }
  },
});

// Action creators are generated for each case reducer function
export const { updateHistory, updateTitle } = historySlice.actions;
export const updateHistoryAsync = (link) => (dispatch) =>
  dispatch(updateHistory(link));
export const currentHistory = (state) => state.historySlice?.history;

export const updateTitleAsync = (newTitle) => (dispatch) =>
  dispatch(updateTitle(newTitle));
export const currentTitle = (state) => state.historySlice?.pageTitle;

export default historySlice.reducer;
