// pages/_app.js
import React from "react";
import { Provider } from "react-redux";
import { LicenseManager } from "ag-grid-enterprise";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { SnackbarProvider } from "notistack";
import Slide from "@mui/material/Slide";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import Script from "next/script";
import { getEnv } from "utils/env";
import AuthGuard from "../components/AuthGuard";
import theme from "../theme/theme-config";
import { store } from "../store";
import "../theme/main.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../theme/table-styles.scss";

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-045043 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Caltana, Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Caltana )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Caltana )_need_to_be_licensed___( Caltana )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 20 September 2024 )____[v2]_MTcyNjc4NjgwMDAwMA==5c52d208817006ae5e65ac5937e5758b"
);

// Datadog RUM configuration definition: https://docs.datadoghq.com/real_user_monitoring/browser/
// Disable datadog for local development environments
if (
  process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
) {
  datadogLogs.init({
    clientToken: "pubb37150738e8ee63ccfaf56d6cf9e8eca",
    site: "datadoghq.com",
    service: "caltana",
    env: getEnv(),
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    forwardConsoleLogs: ["error", "warn"],
    forwardErrorsToLogs: true,
    forwardReports: "all",
    sessionSampleRate: 100,
    storeContextsAcrossPages: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
  });
  datadogRum.init({
    applicationId: "9e99fec9-e6f8-409c-b176-13e9f06ac977",
    clientToken: "pubb37150738e8ee63ccfaf56d6cf9e8eca",
    site: "datadoghq.com",
    service: "caltana",
    env: getEnv(),
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [
      "https://dev-api.caltana.com",
      "https://app-api.caltana.com",
      /https:\/\/dev-api-caltana-pr-\d*\.onrender\.com/,
    ],
  });
  // eslint-disable-next-line no-undef
  DD_RUM.startSessionReplayRecording();
}

export default function MyApp(props) {
  const { Component, pageProps } = props;
  const getLayout = Component.getLayout || ((page) => page);
  const isUnprotected = Component.isUnprotected || false;

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
          hideIconVariant={false}
          dense={false}
        >
          <CssBaseline />
          {/* the pages which have isUnprotected(true) can be accessed without any credentials */}
          {isUnprotected ? (
            getLayout(<Component {...pageProps} />)
          ) : (
            <AuthGuard>{getLayout(<Component {...pageProps} />)}</AuthGuard>
          )}
          <ToastContainer autoClose={1000} />
        </SnackbarProvider>
      </ThemeProvider>

      <Script
        // https://nextjs.org/docs/basic-features/script#afterinteractive
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(apiKey){
            (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
            v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
          })('00a1de14-5365-4d26-4e11-1a111461d37e');
        `,
        }}
      />

      <Script
        // Caltana Help Center Zendesk widget
        // https://nextjs.org/docs/basic-features/script#lazyonload
        strategy="lazyOnload"
        src="https://static.zdassets.com/ekr/snippet.js?key=3ddfa314-91a5-47e6-8e54-2d1c4dcc8a50"
        id="ze-snippet"
      />
    </Provider>
  );
}
