/* eslint-disable @typescript-eslint/no-explicit-any */
import { datadogRum } from "@datadog/browser-rum";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

import { ToastPosition, toast } from "react-toastify";
import { HttpStatusCode } from "./httpStatusCodes";

export type ApiErrorResponse = FetchBaseQueryError & {
  error: string;
};

export interface ApiResponseLogger {
  log: (message: string) => void;
}

export interface ApiResponseErrorRenderer {
  render: (errorMessage: string) => void;
}

/**
 * The constructor is also being exported to provide different callsites to customise the `ResponseHandler`
 * by providing the `ApiResponseLogger` and `ApiResponseErrorRenderer` to be used.
 *
 * An example for this usage is in the unit tests where mocked versions of those dependencies have been provided to
 * facilitate testing without io side-effects.
 */

export const ResponseHandler = (
  logger: ApiResponseLogger,
  errorResponseRenderer: ApiResponseErrorRenderer
) => {
  const displayErrorResponse = (
    errorMessage: string,
    displayableErrorMessage: string
  ) => {
    logger.log(
      `[ApiRequestError] ${displayableErrorMessage} : ${errorMessage}`
    );
    errorResponseRenderer.render(displayableErrorMessage);
  };

  const processErrorResponse = (
    errorResponse: Partial<ApiErrorResponse>,
    displayableErrorMessage: string
  ): Record<"error", unknown> => {
    displayErrorResponse(
      JSON.stringify(errorResponse.data),
      displayableErrorMessage
    );
    return { error: errorResponse.data };
  };

  /**
   *
   * @param response: `QueryReturnValue` that holds APIs response processed by RTK
   * @param successResponseHandler: A callback to call for successful API responses. It takes in the data returned in the response
   * @param displayableErrorMessage: A human readbale message to display to the user in case of errros
   * @returns either an object that contain errors from the API or the result of invoking the `successResponseHandler`
   */

  const handleApiResponse = (
    response: QueryReturnValue<unknown, Partial<ApiErrorResponse>, unknown>,
    successResponseHandler: (data: unknown) => Record<string, unknown>,
    displayableErrorMessage: string
  ): Record<string, unknown> => {
    if (response.error)
      return processErrorResponse(response.error, displayableErrorMessage);
    return successResponseHandler(response.data);
  };

  /**
   *
   * @param exception: Exception to handle
   * @param displayableErrorMessage: A human readable message to display to the user
   * @returns An error object containing the exception message
   */

  const handleApiResponseException = (
    exception: Error,
    displayableErrorMessage: string
  ) => {
    displayErrorResponse(exception.message, displayableErrorMessage);
    return {
      error: exception.message,
    };
  };

  /**
   *
   * @param successResponseStatus: An http response code that's considered to be successful for the request
   * @param responseStatus : An http response code that was returned for the request
   * @param displayableErrorMessage : A human readable message to display to the user in case `successResponseStatus` doesn't match `responseStatus`
   * @param errorMessageResolver : A callable to retrieve detail error message from the response
   * @returns a bool indicating whether `successResponseStatus` matched `responseStatus`
   */
  const handleApiResponseStatus = (
    successResponseStatus: HttpStatusCode,
    responseStatus: number,
    displayableErrorMessage: string,
    errorMessageResolver: () => string
  ) => {
    const isSuccessful = successResponseStatus.valueOf() === responseStatus;
    if (!isSuccessful) {
      displayErrorResponse(errorMessageResolver(), displayableErrorMessage);
    }

    return isSuccessful;
  };

  return {
    /**
     * This takes in raw API response as returned by RTK in a `QueryReturnValue` object.
     * It introspects the response object's `error` field to check if there any errors and appropriately handles the error
     */
    handleApiResponse,

    /**
     * This handles an exception thrown within an RTK's mutation/query block.
     * It's a handy method to put in the `catch` block and pass it the exception object
     */
    handleApiResponseException,

    /**
     * This handles specific response codes from the API that the call would like handled as failures.
     * e.g for some `POST` requests, if the caller expects the backend to return `200` as a success http code, we can
     * explicitily check for that, and consider the request as failed if any mismatch.
     */
    handleApiResponseStatus,
  };
};

export const DatadogRumApiResponseLogger: ApiResponseLogger = {
  log: (errorMessage: string) => datadogRum.addError(errorMessage),
};

export const ToastApiResponseErrorRenderer: ApiResponseErrorRenderer = {
  render: (errorMessage: string) =>
    toast.error(errorMessage, {
      position: "top-right",
      autoClose: false,
      closeOnClick: true,
      draggable: true,
      theme: "colored",
    }),
};

/**
 * `ResponseHandler` requires:
 *    - `ApiResponseLogger` to be provided for logging error responses
 *    - `ApiResponseErrorRenderer` to be provided for rendering human sensible failures to users
 *
 * This export provides a default implementation where:
 *  -  `ApiResponseErrorRenderer` is a Datadog logger for logging messages to Datadog
 *  -  `ApiResponseErrorRenderer` is a Toast renderer for displaying error messages in a dismissable toast
 */

export const ApiResponseHandler = ResponseHandler(
  DatadogRumApiResponseLogger,
  ToastApiResponseErrorRenderer
);
