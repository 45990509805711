/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { acceptTermsAsync } from "store/slices/authUserSlice";
import { ApiResponseHandler } from "./apiResponseHandler";
import { baseQuery } from "./baseQuery";
import { HttpStatusCode } from "./httpStatusCodes";

interface AcceptSupplierTermsRequest {
  isTermsAccepted: boolean;
}

interface AcceptSupplierTermsResponse {
  accepted_terms_of_service: boolean;
  accepted_terms_of_service_date: string;
}

export const onboardingApi = createApi({
  baseQuery,
  reducerPath: "onboardingApi",
  endpoints: (build) => ({
    acceptSupplierTerms: build.mutation<
      AcceptSupplierTermsResponse,
      AcceptSupplierTermsRequest
    >({
      query: ({ isTermsAccepted }) => ({
        url: `suppliers/accept_terms/`,
        method: "POST",
        body: {
          accepted_terms_of_service: isTermsAccepted,
        },
        validateStatus: (response) =>
          ApiResponseHandler.handleApiResponseStatus(
            HttpStatusCode.HTTP_200_OK,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            response?.status,
            "Failed to accept supplier terms",
            () => "Failed to accept supplier terms"
          ),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.accepted_terms_of_service) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            acceptTermsAsync(dispatch);
          }
          // eslint-disable-next-line no-empty
        } catch {}
      },
    }),
  }),
});

export const { useAcceptSupplierTermsMutation } = onboardingApi;
